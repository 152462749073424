import { warehouseService } from '@/api/';
import { WarehouseDetails } from './../../../../resource/model/database/warehouse.d';
import { TagsViewModule } from '@/store/modules/tags-view';
import { messageError, translation } from '@/utils';
import { Component, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router/types/router';
import { PublicApproveStatusEnum, ResourceStatusEnum } from '@/resource/enum';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { StoreLocationList } from '@/resource/model';
Component.registerHooks(['beforeRouteLeave']);
@Component({
  name: 'DetailsWarehouse',
  components: {}
})
export default class DetailsWarehouse extends Vue {
  /**
   *  仓库ID
   */
  public id = Number(this.$route.query.id);

  public disabled: boolean = true;

  public form: WarehouseDetails = {
    id: 0,
    name: '',
    code: '',
    positionFlag: -1,
    remark: '',
    approveStatus: -1,
    enableStatus: -1,
    createUserName: '',
    createTime: '',
    updateUserName: '',
    updateTime: '',
    approveTime: '',
    approveUserName: '',
    forbiddenTime: '',
    forbiddenUserName: ''
  };

  public tableOption: OsTableOption<StoreLocationList> = {
    loading: false,
    data: [
      {
        id: 1,
        code: 'c0001',
        name: '存货类别',
        approveStatus: 1,
        enableStatus: 1,
        createTime: '',
        createUserName: '科密',
        remark: '描述'
      },
      {
        id: 2,
        code: 'c0002',
        name: '存货类别',
        approveStatus: 1,
        enableStatus: 1,
        createTime: '',
        createUserName: '科密',
        remark: '描述'
      },
      {
        id: 3,
        code: 'c0003',
        name: '存货类别',
        approveStatus: 1,
        enableStatus: 1,
        createTime: '',
        createUserName: '科密',
        remark: '描述'
      }
    ],
    fit: true
  };
  /**
   * 默认的表格列配置
   */
  public defaultColumnOptions: Array<OsTableColumn<StoreLocationList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'index',
      label: 'common.index',
      minWidth: '50px',
      fixed: true
    },
    {
      prop: 'code',
      label: 'storeLocation.code',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'name',
      label: 'storeLocation.name',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'remark',
      label: 'database.description',
      minWidth: '100px',
      showOverflowTooltip: true
    }
  ];

  private get getApproveStatusI18Key(): string {
    return translation('publicApproveStatus.' + PublicApproveStatusEnum[this.form.approveStatus]);
  }
  private get getStatusI18Key(): string {
    let res;
    switch (this.form.enableStatus) {
      case ResourceStatusEnum.using:
        res = 'common.startUsing';
        break;
      case ResourceStatusEnum.disabled:
        res = 'common.disabled';
        break;
      default:
        res = 'common.unKnownStatus';
        break;
    }
    return translation(res);
  }

  public activated(): void {
    // 当id变化时，重新加载数据
    if (!isNaN(this.id) && this.id !== Number(this.$route.query.id)) {
      this.id = Number(this.$route.query.id);

      this.getDetails(this.id);
    }
  }

  public created(): void {
    if (!isNaN(this.id)) {
      this.getDetails(this.id);
    }
  }

  public beforeRouteLeave(to: Route, from: Route, next: Function): void {
    TagsViewModule.DelView(this.$route);
    next();
  }
  private getDetails(id: number): void {
    warehouseService
      .getById(id)
      .then(res => {
        Object.assign(this.form, res);

        const storelocationList = res.storeLocationList || [];
        storelocationList.forEach((x: any, index: number) => {
          x.index = index + 1;
        });

        this.tableOption.data = storelocationList;
      })
      .catch(error => {
        messageError(error);
      });
  }
}
